// 会议管理
import request from '@/utils/request';

export default {
  list(params) {
    return request.get("/merchant/contract", { params });
  },
  detail(params) {
    return request.get("/merchant/contract/detail", { params });
  },
  
  create(data) {
    return request.post('/merchant/contract/create', data);
  },
  update(data) {
    return request.post('/merchant/contract/update', data);
  },
  remove(data) {
    return request.post('/merchant/contract/remove', data);
  },


};
