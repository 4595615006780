// 会议管理
import request from '@/utils/request';

export default {
  // list(params) {
  //   return request.get(params.apiName, { params });
  // },

  create(data) {
    return request.post('/role/create', data);
  },
  update(data) {
    return request.post('/role/update', data);
  },
  remove(data) {
    return request.post('/role/remove', data);
  },
  detail(id) {
    return request.get('/role/detail?id='+id);
  },
 
  getList() {
    return request.get("/role");
  },
};
