// 会议管理
import request from '@/utils/request';

export default {


  uploadPic(data) {
    return request.post('/file/upload', data);
  },

};
