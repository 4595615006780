// 会议管理
import request from '@/utils/request';

export default {
  create(data) {
    return request.post('/market/businessType/create', data);
  },
  update(data) {
    return request.post('/market/businessType/update', data);
  },
  remove(data) {
    return request.post('/market/businessType/remove', data);
  },
  getList(data) {
    return request.post('/dh/equipment/selectDoorEquipmentInfo', data);
  },
  changeDoor(data){
    return request.post(data.apiName, data.params);
    
  },
};
