// 会议室
import request from '@/utils/request';

export default {

  getAllList(params) {
    return request.get('/system/dataDictionary/getDictionaryParameter', { params });
  },



};
