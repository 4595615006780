// 会议管理
import request from '@/utils/request';

export default {
  list(params) {
    return request.get('/bigScreenMgr', { params });
  },
  create(data) {
    return request.post('/bigScreenMgr/create', data);
  },
  update(data) {
    return request.post('/bigScreenMgr/update', data);
  },
  remove(data) {
    return request.post('/bigScreenMgr/remove', data);
  },
  detail(data) {
    return request.post('/bigScreenMgr/detail', data);
  },


};
