// 会议管理
import request from '@/utils/request';

export default {
  // list(params) {
  //   return request.get(params.apiName, { params });
  // },

  create(data) {
    return request.post('/market/businessType/create', data);
  },
  update(data) {
    return request.post('/market/businessType/update', data);
  },
  remove(data) {
    return request.post('/market/businessType/remove', data);
  },

  getTreeList() {
    return request.get("/market/businessType/treeList");
  },
  getAllList() {
    return request.get("/market/businessType/allList");
  },
  getAllWeighingTool() {
    return request.get("/market/weighingTool/dropDownList");
  },

  
};
