// 会议管理
import request from '@/utils/request';

export default {
  // list(params) {
  //   return request.get(params.apiName, { params });
  // },

  create(data) {
    return request.post('/permissionVue/create', data);
  },
  update(data) {
    return request.post('/permissionVue/update', data);
  },
  remove(data) {
    return request.post('/permissionVue/remove', data);
  },

  getTreeList() {
    return request.get("/permissionVue");
  },
};
